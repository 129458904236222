/* generated by Svelte v1.64.1 */
"use strict";
var Fuse = require("fuse.js");
Fuse = (Fuse && Fuse.__esModule) ? Fuse["default"] : Fuse;

function data() {
  return {
    searchList: [],
    filteredList: [],
    fuse: null,
    isOpen: false
  }
};

var methods = {
  openSearch() {
    this.set({isOpen: true});
    this.refs.input.focus();
  },
  closeSearch() {
    this.set({isOpen: false});
  },
  toggleSearch() {
    if(this.get('isOpen')) {
      this.closeSearch();
    } else {
      this.openSearch();
    }
  },
  isClosable(e) {
    if (e.keyCode === 27) {
      this.closeSearch();
    }
  },
  setFuse(searchList) {
    if(searchList.length < 1) {
      return;
    }
    this.set({
      fuse: new Fuse(searchList, {
        shouldSort: true,
        findAllMatches: true,
        includeMatches: true,
        threshold: 0.3,
        location: 0,
        distance: 10000,
        maxPatternLength: 32,
        minMatchCharLength: 3,
        keys: [
          "content"
        ]
      })
    });
  },
  handleSearch(e) {
    const text = e.target.value;
    if(text.length > 3) {
      this.set({
        filteredList: this.get('fuse').search(e.target.value)
      });
    } else {
      this.set({
        filteredList: []
      });
    }
    console.log(this.get('filteredList'));
  }
};

function oncreate() {
  this.observe('searchList', (list) => {
    this.fuse = this.setFuse(list);
  });
};

function create_main_fragment(component, state) {
	var div, div_1, svg, use, text_1, div_2, div_2_class_value, text_2, div_3, input, text_3, text_4, div_3_class_value;

	function click_handler(event) {
		component.toggleSearch();
	}

	function click_handler_1(event) {
		component.closeSearch();
	}

	function input_handler(event) {
		component.handleSearch(event);
	}

	function keydown_handler(event) {
		component.isClosable(event);
	}

	var if_block = (state.filteredList.length > 1) && create_if_block(component, state);

	var if_block_1 = (state.filteredList.length < 1) && create_if_block_1(component, state);

	return {
		c: function create() {
			div = createElement("div");
			div_1 = createElement("div");
			svg = createSvgElement("svg");
			use = createSvgElement("use");
			text_1 = createText("\n  \n  ");
			div_2 = createElement("div");
			text_2 = createText("\n\n  ");
			div_3 = createElement("div");
			input = createElement("input");
			text_3 = createText("\n    ");
			if (if_block) if_block.c();
			text_4 = createText("\n\n    ");
			if (if_block_1) if_block_1.c();
			this.h();
		},

		h: function hydrate() {
			setXlinkAttribute(use, "xlink:href", "img/icon-search.svg#icon");
			setAttribute(use, "preserveAspectRatio", "xMidYMid");
			addListener(svg, "click", click_handler);
			setAttribute(svg, "class", "svg-icon");
			setAttribute(svg, "xmlns", "http://www.w3.org/2000/svg");
			setAttribute(svg, "role", "img");
			setAttribute(svg, "viewBox", "0 0 15 15");
			div_1.className = "btn btn-svg";
			addListener(div_2, "click", click_handler_1);
			div_2.className = div_2_class_value = "SearchListComp--overlay " + ((state.isOpen) ? 'active' : '');
			addListener(input, "input", input_handler);
			addListener(input, "keydown", keydown_handler);
			setAttribute(input, "type", "text");
			input.className = "filter-input";
			input.placeholder = "Search here...";
			div_3.className = div_3_class_value = "search-popup " + ((state.isOpen)? 'open' : '');
			div.className = "SearchListComp";
		},

		m: function mount(target, anchor) {
			insertNode(div, target, anchor);
			appendNode(div_1, div);
			appendNode(svg, div_1);
			appendNode(use, svg);
			appendNode(text_1, div);
			appendNode(div_2, div);
			appendNode(text_2, div);
			appendNode(div_3, div);
			appendNode(input, div_3);
			component.refs.input = input;
			appendNode(text_3, div_3);
			if (if_block) if_block.m(div_3, null);
			appendNode(text_4, div_3);
			if (if_block_1) if_block_1.m(div_3, null);
		},

		p: function update(changed, state) {
			if ((changed.isOpen) && div_2_class_value !== (div_2_class_value = "SearchListComp--overlay " + ((state.isOpen) ? 'active' : ''))) {
				div_2.className = div_2_class_value;
			}

			if (state.filteredList.length > 1) {
				if (if_block) {
					if_block.p(changed, state);
				} else {
					if_block = create_if_block(component, state);
					if_block.c();
					if_block.m(div_3, text_4);
				}
			} else if (if_block) {
				if_block.u();
				if_block.d();
				if_block = null;
			}

			if (state.filteredList.length < 1) {
				if (!if_block_1) {
					if_block_1 = create_if_block_1(component, state);
					if_block_1.c();
					if_block_1.m(div_3, null);
				}
			} else if (if_block_1) {
				if_block_1.u();
				if_block_1.d();
				if_block_1 = null;
			}

			if ((changed.isOpen) && div_3_class_value !== (div_3_class_value = "search-popup " + ((state.isOpen)? 'open' : ''))) {
				div_3.className = div_3_class_value;
			}
		},

		u: function unmount() {
			detachNode(div);
			if (if_block) if_block.u();
			if (if_block_1) if_block_1.u();
		},

		d: function destroy() {
			removeListener(svg, "click", click_handler);
			removeListener(div_2, "click", click_handler_1);
			removeListener(input, "input", input_handler);
			removeListener(input, "keydown", keydown_handler);
			if (component.refs.input === input) component.refs.input = null;
			if (if_block) if_block.d();
			if (if_block_1) if_block_1.d();
		}
	};
}

// (14:6) {{#each filteredList as item}}
function create_each_block(component, state) {
	var item = state.item, each_value = state.each_value, item_index = state.item_index;
	var li, a, div, text_value = item.item.title, text, text_1, div_1, text_2_value = item.item.content.substr(0, 100), text_2, text_3, a_href_value;

	return {
		c: function create() {
			li = createElement("li");
			a = createElement("a");
			div = createElement("div");
			text = createText(text_value);
			text_1 = createText("\n          ");
			div_1 = createElement("div");
			text_2 = createText(text_2_value);
			text_3 = createText("...");
			this.h();
		},

		h: function hydrate() {
			div.className = "section-title";
			div_1.className = "section-text";
			addListener(a, "click", click_handler);
			a.href = a_href_value = "#/" + item.item.id;

			a._svelte = {
				component: component
			};

			li.className = "item";
		},

		m: function mount(target, anchor) {
			insertNode(li, target, anchor);
			appendNode(a, li);
			appendNode(div, a);
			appendNode(text, div);
			appendNode(text_1, a);
			appendNode(div_1, a);
			appendNode(text_2, div_1);
			appendNode(text_3, div_1);
		},

		p: function update(changed, state) {
			item = state.item;
			each_value = state.each_value;
			item_index = state.item_index;
			if ((changed.filteredList) && text_value !== (text_value = item.item.title)) {
				text.data = text_value;
			}

			if ((changed.filteredList) && text_2_value !== (text_2_value = item.item.content.substr(0, 100))) {
				text_2.data = text_2_value;
			}

			if ((changed.filteredList) && a_href_value !== (a_href_value = "#/" + item.item.id)) {
				a.href = a_href_value;
			}
		},

		u: function unmount() {
			detachNode(li);
		},

		d: function destroy() {
			removeListener(a, "click", click_handler);
		}
	};
}

// (12:4) {{#if filteredList.length > 1 }}
function create_if_block(component, state) {
	var ul;

	var each_value = state.filteredList;

	var each_blocks = [];

	for (var i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(component, assign(assign({}, state), {
			each_value: each_value,
			item: each_value[i],
			item_index: i
		}));
	}

	return {
		c: function create() {
			ul = createElement("ul");

			for (var i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}
			this.h();
		},

		h: function hydrate() {
			ul.className = "filtered-list";
		},

		m: function mount(target, anchor) {
			insertNode(ul, target, anchor);

			for (var i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(ul, null);
			}
		},

		p: function update(changed, state) {
			var each_value = state.filteredList;

			if (changed.filteredList) {
				for (var i = 0; i < each_value.length; i += 1) {
					var each_context = assign(assign({}, state), {
						each_value: each_value,
						item: each_value[i],
						item_index: i
					});

					if (each_blocks[i]) {
						each_blocks[i].p(changed, each_context);
					} else {
						each_blocks[i] = create_each_block(component, each_context);
						each_blocks[i].c();
						each_blocks[i].m(ul, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].u();
					each_blocks[i].d();
				}
				each_blocks.length = each_value.length;
			}
		},

		u: function unmount() {
			detachNode(ul);

			for (var i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].u();
			}
		},

		d: function destroy() {
			destroyEach(each_blocks);
		}
	};
}

// (25:4) {{#if filteredList.length < 1 }}
function create_if_block_1(component, state) {
	var h2;

	return {
		c: function create() {
			h2 = createElement("h2");
			h2.textContent = "No matches found.";
			this.h();
		},

		h: function hydrate() {
			h2.className = "empty-message";
		},

		m: function mount(target, anchor) {
			insertNode(h2, target, anchor);
		},

		u: function unmount() {
			detachNode(h2);
		},

		d: noop
	};
}

function click_handler(event) {
	var component = this._svelte.component;
	component.closeSearch();
}

function SvelteComponent(options) {
	init(this, options);
	this.refs = {};
	this._state = assign(data(), options.data);

	var self = this;
	var _oncreate = function() {
		var changed = { isOpen: 1, filteredList: 1 };
		oncreate.call(self);
		self.fire("update", { changed: changed, current: self._state });
	};

	if (!options.root) {
		this._oncreate = [];
	}

	this._fragment = create_main_fragment(this, this._state);

	this.root._oncreate.push(_oncreate);

	if (options.target) {
		this._fragment.c();
		this._mount(options.target, options.anchor);

		callAll(this._oncreate);
	}
}

assign(SvelteComponent.prototype, {
 	destroy: destroy,
 	get: get,
 	fire: fire,
 	observe: observe,
 	on: on,
 	set: set,
 	teardown: destroy,
 	_set: _set,
 	_mount: _mount,
 	_unmount: _unmount,
 	_differs: _differs
 });
assign(SvelteComponent.prototype, methods);

SvelteComponent.prototype._recompute = noop;

function createElement(name) {
	return document.createElement(name);
}

function createSvgElement(name) {
	return document.createElementNS('http://www.w3.org/2000/svg', name);
}

function createText(data) {
	return document.createTextNode(data);
}

function setXlinkAttribute(node, attribute, value) {
	node.setAttributeNS('http://www.w3.org/1999/xlink', attribute, value);
}

function setAttribute(node, attribute, value) {
	node.setAttribute(attribute, value);
}

function addListener(node, event, handler) {
	node.addEventListener(event, handler, false);
}

function insertNode(node, target, anchor) {
	target.insertBefore(node, anchor);
}

function appendNode(node, target) {
	target.appendChild(node);
}

function detachNode(node) {
	node.parentNode.removeChild(node);
}

function removeListener(node, event, handler) {
	node.removeEventListener(event, handler, false);
}

function assign(tar, src) {
	for (var k in src) tar[k] = src[k];
	return tar;
}

function destroyEach(iterations) {
	for (var i = 0; i < iterations.length; i += 1) {
		if (iterations[i]) iterations[i].d();
	}
}

function noop() {}

function init(component, options) {
	component._handlers = blankObject();
	component._bind = options._bind;

	component.options = options;
	component.root = options.root || component;
	component.store = component.root.store || options.store;
}

function callAll(fns) {
	while (fns && fns.length) fns.shift()();
}

function destroy(detach) {
	this.destroy = noop;
	this.fire('destroy');
	this.set = this.get = noop;

	if (detach !== false) this._fragment.u();
	this._fragment.d();
	this._fragment = this._state = null;
}

function get(key) {
	return key ? this._state[key] : this._state;
}

function fire(eventName, data) {
	var handlers =
		eventName in this._handlers && this._handlers[eventName].slice();
	if (!handlers) return;

	for (var i = 0; i < handlers.length; i += 1) {
		var handler = handlers[i];

		if (!handler.__calling) {
			handler.__calling = true;
			handler.call(this, data);
			handler.__calling = false;
		}
	}
}

function observe(key, callback, options) {
	var fn = callback.bind(this);

	if (!options || options.init !== false) {
		fn(this.get()[key], undefined);
	}

	return this.on(options && options.defer ? 'update' : 'state', function(event) {
		if (event.changed[key]) fn(event.current[key], event.previous && event.previous[key]);
	});
}

function on(eventName, handler) {
	if (eventName === 'teardown') return this.on('destroy', handler);

	var handlers = this._handlers[eventName] || (this._handlers[eventName] = []);
	handlers.push(handler);

	return {
		cancel: function() {
			var index = handlers.indexOf(handler);
			if (~index) handlers.splice(index, 1);
		}
	};
}

function set(newState) {
	this._set(assign({}, newState));
	if (this.root._lock) return;
	this.root._lock = true;
	callAll(this.root._beforecreate);
	callAll(this.root._oncreate);
	callAll(this.root._aftercreate);
	this.root._lock = false;
}

function _set(newState) {
	var oldState = this._state,
		changed = {},
		dirty = false;

	for (var key in newState) {
		if (this._differs(newState[key], oldState[key])) changed[key] = dirty = true;
	}
	if (!dirty) return;

	this._state = assign(assign({}, oldState), newState);
	this._recompute(changed, this._state);
	if (this._bind) this._bind(changed, this._state);

	if (this._fragment) {
		this.fire("state", { changed: changed, current: this._state, previous: oldState });
		this._fragment.p(changed, this._state);
		this.fire("update", { changed: changed, current: this._state, previous: oldState });
	}
}

function _mount(target, anchor) {
	this._fragment[this._fragment.i ? 'i' : 'm'](target, anchor || null);
}

function _unmount() {
	if (this._fragment) this._fragment.u();
}

function _differs(a, b) {
	return a != a ? b == b : a !== b || ((a && typeof a === 'object') || typeof a === 'function');
}

function blankObject() {
	return Object.create(null);
}

module.exports = SvelteComponent;