/* generated by Svelte v1.64.1 */
"use strict";
var Hammer = require("hammerjs");
Hammer = (Hammer && Hammer.__esModule) ? Hammer["default"] : Hammer;

function data() {
  return {
    menuItems: [],
    menuItemsFlat: [],
    isOpen: false,
    prevY: 0
  }
};

function swipeleft(node, callback) {
  let h = new Hammer(node);
  h.on('swipeleft', callback);
};

function swiperight(node, callback) {
  let h = new Hammer(node);
  h.on('swiperight', callback);
};

var methods = {
  onAppScroll (y) {
    let menuItems = this.get('menuItemsFlat');
    
    for(let [key, item] of menuItems.entries()) {
      let el = document.getElementById(item.id);
      let elemTop = el.getBoundingClientRect().top;
      let elemBottom = el.getBoundingClientRect().bottom;

      // Is on screen
      if ((elemTop >= 0) && (elemBottom <= window.innerHeight)) {
        // Remove active from all menu items
        [...document.querySelectorAll('.menu li.active')].map(i => i.classList.remove('active'));

        // Get item to highlight
        let menuToHighlight = document.getElementById(`menu-${item.id}`);
        
        // Highlight item
        menuToHighlight.classList.add('active');

        let sectionToShow = menuToHighlight.parentNode;

        // If its an item inside subsection, make subsection (father) visible
        if(sectionToShow.classList.contains('subsection')) {
          [...document.querySelectorAll('.menu .subsection')].map(i => i.classList.remove('show'));
          sectionToShow.classList.add('show');
        }

        // If its an item father of a subsection, make subsection (child) visible
        if(menuToHighlight.querySelectorAll('.subsection').length > 0) {
          [...document.querySelectorAll('.menu .subsection')].map(i => i.classList.remove('show'));
          menuToHighlight.querySelector('.subsection').classList.add('show');
        }

        // Stop loop
        break;
      }
    }
  },
  startMenu (menuOpts) {
    this.set(menuOpts);
    setTimeout(() => {
      this.scrollToURLMenu();
    }, 10);
  },
  scrollTop() {
    window.scrollTo(0, 0);
    this.close();
  },
  scrollToURLMenu () {
    let hash = window.location.hash.substr(2);
    let filtered = this.get('menuItemsFlat').filter(item => item.id === hash);

    if (filtered.length > 0) {
      let heading = document.getElementById(filtered[0].id);
      let top = window.scrollY + heading.getBoundingClientRect().top - 70;
      window.scrollTo(0, top);
    } else {
      window.scrollTo(0, 1);
    }
  },
  toggle () {
    this.set({isOpen: !this.refs.menu.isOpen});
    return this.refs.menu.isOpen;
  },
  close () {
    this.set({isOpen: false});
  },
  open () {
    this.set({isOpen: true});
  },
  scrollToItem (item, e) {
    if(e) {
      e.preventDefault();
    }

    window.location.hash = '/' + item.id;
    let menuItems = this.get('menuItemsFlat');
    let top = window.scrollY + document.getElementById(item.id).getBoundingClientRect().top - 70;
    window.scrollTo(0, top);

    this.fire('mobilemenu', {action: 'CLOSE'});
  }
};

function oncreate() {
  window.onhashchange = () => {
    this.scrollToURLMenu();
  }
};

function create_main_fragment(component, state) {
	var div, div_1, div_1_class_value, text, div_2, img, text_1, ul, swipeleft_handler, div_2_class_value;

	function onwindowscroll(event) {
		component.onAppScroll(this.scrollY);
	}
	window.addEventListener("scroll", onwindowscroll);

	function click_handler(event) {
		component.fire('mobilemenu', {action: 'CLOSE'});
	}

	var each_value = state.menuItems;

	var each_blocks = [];

	for (var i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(component, assign(assign({}, state), {
			each_value: each_value,
			item: each_value[i],
			item_index: i
		}));
	}

	return {
		c: function create() {
			div = createElement("div");
			div_1 = createElement("div");
			text = createText("\n  ");
			div_2 = createElement("div");
			img = createElement("img");
			text_1 = createText("\n    ");
			ul = createElement("ul");

			for (var i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}
			this.h();
		},

		h: function hydrate() {
			addListener(div_1, "click", click_handler);
			div_1.className = div_1_class_value = "MenuComp--overlay " + ((state.isOpen) ? 'active' : '');
			img.src = "img/logo-green.svg";
			img.alt = "Sumcoin Docs";
			img.className = "logo";
			ul.className = "menu";

			swipeleft_handler = swipeleft.call(component, div_2, function(event) {
				component.fire('mobilemenu', {action: 'CLOSE'});
			});

			div_2.className = div_2_class_value = "MenuComp " + ((state.isOpen) ? 'open' : '');
		},

		m: function mount(target, anchor) {
			insertNode(div, target, anchor);
			appendNode(div_1, div);
			component.refs.overlay = div_1;
			appendNode(text, div);
			appendNode(div_2, div);
			appendNode(img, div_2);
			appendNode(text_1, div_2);
			appendNode(ul, div_2);

			for (var i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(ul, null);
			}

			component.refs.menu = div_2;
		},

		p: function update(changed, state) {
			if ((changed.isOpen) && div_1_class_value !== (div_1_class_value = "MenuComp--overlay " + ((state.isOpen) ? 'active' : ''))) {
				div_1.className = div_1_class_value;
			}

			var each_value = state.menuItems;

			if (changed.menuItems) {
				for (var i = 0; i < each_value.length; i += 1) {
					var each_context = assign(assign({}, state), {
						each_value: each_value,
						item: each_value[i],
						item_index: i
					});

					if (each_blocks[i]) {
						each_blocks[i].p(changed, each_context);
					} else {
						each_blocks[i] = create_each_block(component, each_context);
						each_blocks[i].c();
						each_blocks[i].m(ul, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].u();
					each_blocks[i].d();
				}
				each_blocks.length = each_value.length;
			}

			if ((changed.isOpen) && div_2_class_value !== (div_2_class_value = "MenuComp " + ((state.isOpen) ? 'open' : ''))) {
				div_2.className = div_2_class_value;
			}
		},

		u: function unmount() {
			detachNode(div);

			for (var i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].u();
			}
		},

		d: function destroy() {
			window.removeEventListener("scroll", onwindowscroll);

			removeListener(div_1, "click", click_handler);
			if (component.refs.overlay === div_1) component.refs.overlay = null;

			destroyEach(each_blocks);

			swipeleft_handler[swipeleft_handler.destroy ? 'destroy' : 'teardown']();
			if (component.refs.menu === div_2) component.refs.menu = null;
		}
	};
}

// (7:6) {{#each menuItems as item}}
function create_each_block(component, state) {
	var item = state.item, each_value = state.each_value, item_index = state.item_index;
	var li, a, text_value = item.label, text, a_href_value, text_1, li_id_value, li_class_value;

	var if_block = (item.children.length > 0) && create_if_block(component, state);

	return {
		c: function create() {
			li = createElement("li");
			a = createElement("a");
			text = createText(text_value);
			text_1 = createText("\n            ");
			if (if_block) if_block.c();
			this.h();
		},

		h: function hydrate() {
			addListener(a, "click", click_handler);
			a.href = a_href_value = "#" + item.id;

			a._svelte = {
				component: component,
				each_value: state.each_value,
				item_index: state.item_index
			};

			li.id = li_id_value = "menu-" + item.id;
			li.className = li_class_value = item.type;
		},

		m: function mount(target, anchor) {
			insertNode(li, target, anchor);
			appendNode(a, li);
			appendNode(text, a);
			appendNode(text_1, li);
			if (if_block) if_block.m(li, null);
		},

		p: function update(changed, state) {
			item = state.item;
			each_value = state.each_value;
			item_index = state.item_index;
			if ((changed.menuItems) && text_value !== (text_value = item.label)) {
				text.data = text_value;
			}

			if ((changed.menuItems) && a_href_value !== (a_href_value = "#" + item.id)) {
				a.href = a_href_value;
			}

			a._svelte.each_value = state.each_value;
			a._svelte.item_index = state.item_index;

			if (item.children.length > 0) {
				if (if_block) {
					if_block.p(changed, state);
				} else {
					if_block = create_if_block(component, state);
					if_block.c();
					if_block.m(li, null);
				}
			} else if (if_block) {
				if_block.u();
				if_block.d();
				if_block = null;
			}

			if ((changed.menuItems) && li_id_value !== (li_id_value = "menu-" + item.id)) {
				li.id = li_id_value;
			}

			if ((changed.menuItems) && li_class_value !== (li_class_value = item.type)) {
				li.className = li_class_value;
			}
		},

		u: function unmount() {
			detachNode(li);
			if (if_block) if_block.u();
		},

		d: function destroy() {
			removeListener(a, "click", click_handler);
			if (if_block) if_block.d();
		}
	};
}

// (12:16) {{#each item.children as subitem}}
function create_each_block_1(component, state) {
	var item = state.item, each_value = state.each_value, item_index = state.item_index, subitem = state.subitem, each_value_1 = state.each_value_1, subitem_index = state.subitem_index;
	var li, a, text_value = subitem.label, text, a_href_value, li_id_value, li_class_value;

	return {
		c: function create() {
			li = createElement("li");
			a = createElement("a");
			text = createText(text_value);
			this.h();
		},

		h: function hydrate() {
			addListener(a, "click", click_handler_1);
			a.href = a_href_value = "#" + subitem.id;

			a._svelte = {
				component: component,
				each_value_1: state.each_value_1,
				subitem_index: state.subitem_index
			};

			li.id = li_id_value = "menu-" + subitem.id;
			li.className = li_class_value = subitem.type;
		},

		m: function mount(target, anchor) {
			insertNode(li, target, anchor);
			appendNode(a, li);
			appendNode(text, a);
		},

		p: function update(changed, state) {
			item = state.item;
			each_value = state.each_value;
			item_index = state.item_index;
			subitem = state.subitem;
			each_value_1 = state.each_value_1;
			subitem_index = state.subitem_index;
			if ((changed.menuItems) && text_value !== (text_value = subitem.label)) {
				text.data = text_value;
			}

			if ((changed.menuItems) && a_href_value !== (a_href_value = "#" + subitem.id)) {
				a.href = a_href_value;
			}

			a._svelte.each_value_1 = state.each_value_1;
			a._svelte.subitem_index = state.subitem_index;

			if ((changed.menuItems) && li_id_value !== (li_id_value = "menu-" + subitem.id)) {
				li.id = li_id_value;
			}

			if ((changed.menuItems) && li_class_value !== (li_class_value = subitem.type)) {
				li.className = li_class_value;
			}
		},

		u: function unmount() {
			detachNode(li);
		},

		d: function destroy() {
			removeListener(a, "click", click_handler_1);
		}
	};
}

// (10:12) {{#if item.children.length > 0}}
function create_if_block(component, state) {
	var item = state.item, each_value = state.each_value, item_index = state.item_index;
	var ul;

	var each_value_1 = item.children;

	var each_blocks = [];

	for (var i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(component, assign(assign({}, state), {
			each_value_1: each_value_1,
			subitem: each_value_1[i],
			subitem_index: i
		}));
	}

	return {
		c: function create() {
			ul = createElement("ul");

			for (var i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}
			this.h();
		},

		h: function hydrate() {
			ul.className = "subsection";
		},

		m: function mount(target, anchor) {
			insertNode(ul, target, anchor);

			for (var i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(ul, null);
			}
		},

		p: function update(changed, state) {
			item = state.item;
			each_value = state.each_value;
			item_index = state.item_index;
			var each_value_1 = item.children;

			if (changed.menuItems) {
				for (var i = 0; i < each_value_1.length; i += 1) {
					var each_context = assign(assign({}, state), {
						each_value_1: each_value_1,
						subitem: each_value_1[i],
						subitem_index: i
					});

					if (each_blocks[i]) {
						each_blocks[i].p(changed, each_context);
					} else {
						each_blocks[i] = create_each_block_1(component, each_context);
						each_blocks[i].c();
						each_blocks[i].m(ul, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].u();
					each_blocks[i].d();
				}
				each_blocks.length = each_value_1.length;
			}
		},

		u: function unmount() {
			detachNode(ul);

			for (var i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].u();
			}
		},

		d: function destroy() {
			destroyEach(each_blocks);
		}
	};
}

function click_handler(event) {
	var component = this._svelte.component;
	var each_value = this._svelte.each_value, item_index = this._svelte.item_index, item = each_value[item_index];
	component.scrollToItem(item, event);
}

function click_handler_1(event) {
	var component = this._svelte.component;
	var each_value_1 = this._svelte.each_value_1, subitem_index = this._svelte.subitem_index, subitem = each_value_1[subitem_index];
	component.scrollToItem(subitem, event);
}

function SvelteComponent(options) {
	init(this, options);
	this.refs = {};
	this._state = assign(data(), options.data);

	var self = this;
	var _oncreate = function() {
		var changed = { isOpen: 1, menuItems: 1 };
		oncreate.call(self);
		self.fire("update", { changed: changed, current: self._state });
	};

	if (!options.root) {
		this._oncreate = [];
	}

	this._fragment = create_main_fragment(this, this._state);

	this.root._oncreate.push(_oncreate);

	if (options.target) {
		this._fragment.c();
		this._mount(options.target, options.anchor);

		callAll(this._oncreate);
	}
}

assign(SvelteComponent.prototype, {
 	destroy: destroy,
 	get: get,
 	fire: fire,
 	observe: observe,
 	on: on,
 	set: set,
 	teardown: destroy,
 	_set: _set,
 	_mount: _mount,
 	_unmount: _unmount,
 	_differs: _differs
 });
assign(SvelteComponent.prototype, methods);

SvelteComponent.prototype._recompute = noop;

function assign(tar, src) {
	for (var k in src) tar[k] = src[k];
	return tar;
}

function createElement(name) {
	return document.createElement(name);
}

function createText(data) {
	return document.createTextNode(data);
}

function addListener(node, event, handler) {
	node.addEventListener(event, handler, false);
}

function insertNode(node, target, anchor) {
	target.insertBefore(node, anchor);
}

function appendNode(node, target) {
	target.appendChild(node);
}

function detachNode(node) {
	node.parentNode.removeChild(node);
}

function removeListener(node, event, handler) {
	node.removeEventListener(event, handler, false);
}

function destroyEach(iterations) {
	for (var i = 0; i < iterations.length; i += 1) {
		if (iterations[i]) iterations[i].d();
	}
}

function init(component, options) {
	component._handlers = blankObject();
	component._bind = options._bind;

	component.options = options;
	component.root = options.root || component;
	component.store = component.root.store || options.store;
}

function callAll(fns) {
	while (fns && fns.length) fns.shift()();
}

function destroy(detach) {
	this.destroy = noop;
	this.fire('destroy');
	this.set = this.get = noop;

	if (detach !== false) this._fragment.u();
	this._fragment.d();
	this._fragment = this._state = null;
}

function get(key) {
	return key ? this._state[key] : this._state;
}

function fire(eventName, data) {
	var handlers =
		eventName in this._handlers && this._handlers[eventName].slice();
	if (!handlers) return;

	for (var i = 0; i < handlers.length; i += 1) {
		var handler = handlers[i];

		if (!handler.__calling) {
			handler.__calling = true;
			handler.call(this, data);
			handler.__calling = false;
		}
	}
}

function observe(key, callback, options) {
	var fn = callback.bind(this);

	if (!options || options.init !== false) {
		fn(this.get()[key], undefined);
	}

	return this.on(options && options.defer ? 'update' : 'state', function(event) {
		if (event.changed[key]) fn(event.current[key], event.previous && event.previous[key]);
	});
}

function on(eventName, handler) {
	if (eventName === 'teardown') return this.on('destroy', handler);

	var handlers = this._handlers[eventName] || (this._handlers[eventName] = []);
	handlers.push(handler);

	return {
		cancel: function() {
			var index = handlers.indexOf(handler);
			if (~index) handlers.splice(index, 1);
		}
	};
}

function set(newState) {
	this._set(assign({}, newState));
	if (this.root._lock) return;
	this.root._lock = true;
	callAll(this.root._beforecreate);
	callAll(this.root._oncreate);
	callAll(this.root._aftercreate);
	this.root._lock = false;
}

function _set(newState) {
	var oldState = this._state,
		changed = {},
		dirty = false;

	for (var key in newState) {
		if (this._differs(newState[key], oldState[key])) changed[key] = dirty = true;
	}
	if (!dirty) return;

	this._state = assign(assign({}, oldState), newState);
	this._recompute(changed, this._state);
	if (this._bind) this._bind(changed, this._state);

	if (this._fragment) {
		this.fire("state", { changed: changed, current: this._state, previous: oldState });
		this._fragment.p(changed, this._state);
		this.fire("update", { changed: changed, current: this._state, previous: oldState });
	}
}

function _mount(target, anchor) {
	this._fragment[this._fragment.i ? 'i' : 'm'](target, anchor || null);
}

function _unmount() {
	if (this._fragment) this._fragment.u();
}

function _differs(a, b) {
	return a != a ? b == b : a !== b || ((a && typeof a === 'object') || typeof a === 'function');
}

function noop() {}

function blankObject() {
	return Object.create(null);
}

module.exports = SvelteComponent;