/* generated by Svelte v1.64.1 */
"use strict";
var SearchList = require("./searchlist.component");

var Settings = require("../herodotus-settings.js");
SearchList = (SearchList && SearchList.__esModule) ? SearchList["default"] : SearchList;
Settings = (Settings && Settings.__esModule) ? Settings["default"] : Settings;

function data() {
  return {
    settings: Settings
  }
};

function create_main_fragment(component, state) {
	var div, div_1, svg, use, svg_class_value, text_1, div_2, text_3, text_4, hr, text_5, text_6;

	function click_handler(event) {
		component.fire('mobilemenu', {action: 'TOGGLE'});
	}

	function click_handler_1(event) {
		component.scrollTop();
	}

	var if_block = (state.settings.allow_font_size_control) && create_if_block(component, state);

	var searchlist = new SearchList({
		root: component.root
	});

	component.refs.searchlist = searchlist;

	var if_block_1 = (state.settings.allow_topbar_link) && create_if_block_1(component, state);

	return {
		c: function create() {
			div = createElement("div");
			div_1 = createElement("div");
			svg = createSvgElement("svg");
			use = createSvgElement("use");
			text_1 = createText("\n\n    ");
			div_2 = createElement("div");
			div_2.innerHTML = "<img src=\"img/\" alt=\"Sumcoin Docs\">";
			text_3 = createText("\n\n    ");
			if (if_block) if_block.c();
			text_4 = createText("\n\n    ");
			hr = createElement("hr");
			text_5 = createText("\n\n    ");
			searchlist._fragment.c();
			text_6 = createText("\n\n    ");
			if (if_block_1) if_block_1.c();
			this.h();
		},

		h: function hydrate() {
			setXlinkAttribute(use, "xlink:href", "img/icon-menu-open.svg#icon");
			setAttribute(use, "preserveAspectRatio", "xMidYMid");
			setAttribute(svg, "class", svg_class_value = "svg-icon " + ((!state.isMenuOpen) ? '' : 'off'));
			setAttribute(svg, "xmlns", "http://www.w3.org/2000/svg");
			setAttribute(svg, "role", "img");
			setAttribute(svg, "viewBox", "0 0 40 28");
			addListener(div_1, "click", click_handler);
			div_1.className = "menu-button";
			addListener(div_2, "click", click_handler_1);
			div_2.className = "logo";
			div.className = "TopbarComp";
		},

		m: function mount(target, anchor) {
			insertNode(div, target, anchor);
			appendNode(div_1, div);
			appendNode(svg, div_1);
			appendNode(use, svg);
			appendNode(text_1, div);
			appendNode(div_2, div);
			appendNode(text_3, div);
			if (if_block) if_block.m(div, null);
			appendNode(text_4, div);
			appendNode(hr, div);
			appendNode(text_5, div);
			searchlist._mount(div, null);
			appendNode(text_6, div);
			if (if_block_1) if_block_1.m(div, null);
		},

		p: function update(changed, state) {
			if ((changed.isMenuOpen) && svg_class_value !== (svg_class_value = "svg-icon " + ((!state.isMenuOpen) ? '' : 'off'))) {
				setAttribute(svg, "class", svg_class_value);
			}

			if (state.settings.allow_font_size_control) {
				if (!if_block) {
					if_block = create_if_block(component, state);
					if_block.c();
					if_block.m(div, text_4);
				}
			} else if (if_block) {
				if_block.u();
				if_block.d();
				if_block = null;
			}

			if (state.settings.allow_topbar_link) {
				if (if_block_1) {
					if_block_1.p(changed, state);
				} else {
					if_block_1 = create_if_block_1(component, state);
					if_block_1.c();
					if_block_1.m(div, null);
				}
			} else if (if_block_1) {
				if_block_1.u();
				if_block_1.d();
				if_block_1 = null;
			}
		},

		u: function unmount() {
			detachNode(div);
			if (if_block) if_block.u();
			if (if_block_1) if_block_1.u();
		},

		d: function destroy() {
			removeListener(div_1, "click", click_handler);
			removeListener(div_2, "click", click_handler_1);
			if (if_block) if_block.d();
			searchlist.destroy(false);
			if (component.refs.searchlist === searchlist) component.refs.searchlist = null;
			if (if_block_1) if_block_1.d();
		}
	};
}

// (12:4) {{#if settings.allow_font_size_control}}
function create_if_block(component, state) {
	var div, div_1, text_1, div_2;

	function click_handler(event) {
		component.fire('fontsize', {action: 'DECREASE'});
	}

	function click_handler_1(event) {
		component.fire('fontsize', {action: 'INCREASE'});
	}

	return {
		c: function create() {
			div = createElement("div");
			div_1 = createElement("div");
			div_1.innerHTML = "<svg class=\"svg-icon\" xmlns=\"http://www.w3.org/2000/svg\" role=\"img\" viewBox=\"0 0 26 20\"><use xlink:href=\"img/icon-font-minus.svg#icon\" preserveAspectRatio=\"xMidYMid\"></use>\n        </svg>";
			text_1 = createText("\n      ");
			div_2 = createElement("div");
			div_2.innerHTML = "<svg class=\"svg-icon\" xmlns=\"http://www.w3.org/2000/svg\" role=\"img\" viewBox=\"0 0 26 20\"><use xlink:href=\"img/icon-font-plus.svg#icon\" preserveAspectRatio=\"xMidYMid\"></use>\n        </svg>";
			this.h();
		},

		h: function hydrate() {
			addListener(div_1, "click", click_handler);
			div_1.className = "btn btn-svg v-center";
			addListener(div_2, "click", click_handler_1);
			div_2.className = "btn btn-svg v-center";
			div.className = "btn-group";
		},

		m: function mount(target, anchor) {
			insertNode(div, target, anchor);
			appendNode(div_1, div);
			appendNode(text_1, div);
			appendNode(div_2, div);
		},

		u: function unmount() {
			detachNode(div);
		},

		d: function destroy() {
			removeListener(div_1, "click", click_handler);
			removeListener(div_2, "click", click_handler_1);
		}
	};
}

// (31:4) {{#if settings.allow_topbar_link}}
function create_if_block_1(component, state) {
	var a, span, b, text_1_value = state.settings.topbar_link_label, text_1, a_href_value;

	return {
		c: function create() {
			a = createElement("a");
			span = createElement("span");
			span.textContent = "Contribute to";
			b = createElement("b");
			text_1 = createText(text_1_value);
			this.h();
		},

		h: function hydrate() {
			a.href = a_href_value = state.settings.topbar_link_url;
			a.className = "btn btn-last-link";
		},

		m: function mount(target, anchor) {
			insertNode(a, target, anchor);
			appendNode(span, a);
			appendNode(b, a);
			appendNode(text_1, b);
		},

		p: function update(changed, state) {
			if ((changed.settings) && text_1_value !== (text_1_value = state.settings.topbar_link_label)) {
				text_1.data = text_1_value;
			}

			if ((changed.settings) && a_href_value !== (a_href_value = state.settings.topbar_link_url)) {
				a.href = a_href_value;
			}
		},

		u: function unmount() {
			detachNode(a);
		},

		d: noop
	};
}

function SvelteComponent(options) {
	init(this, options);
	this.refs = {};
	this._state = assign(data(), options.data);

	if (!options.root) {
		this._oncreate = [];
		this._beforecreate = [];
		this._aftercreate = [];
	}

	this._fragment = create_main_fragment(this, this._state);

	if (options.target) {
		this._fragment.c();
		this._mount(options.target, options.anchor);

		this._lock = true;
		callAll(this._beforecreate);
		callAll(this._oncreate);
		callAll(this._aftercreate);
		this._lock = false;
	}
}

assign(SvelteComponent.prototype, {
 	destroy: destroy,
 	get: get,
 	fire: fire,
 	observe: observe,
 	on: on,
 	set: set,
 	teardown: destroy,
 	_set: _set,
 	_mount: _mount,
 	_unmount: _unmount,
 	_differs: _differs
 });

SvelteComponent.prototype._recompute = noop;

function createElement(name) {
	return document.createElement(name);
}

function createSvgElement(name) {
	return document.createElementNS('http://www.w3.org/2000/svg', name);
}

function createText(data) {
	return document.createTextNode(data);
}

function setXlinkAttribute(node, attribute, value) {
	node.setAttributeNS('http://www.w3.org/1999/xlink', attribute, value);
}

function setAttribute(node, attribute, value) {
	node.setAttribute(attribute, value);
}

function addListener(node, event, handler) {
	node.addEventListener(event, handler, false);
}

function insertNode(node, target, anchor) {
	target.insertBefore(node, anchor);
}

function appendNode(node, target) {
	target.appendChild(node);
}

function detachNode(node) {
	node.parentNode.removeChild(node);
}

function removeListener(node, event, handler) {
	node.removeEventListener(event, handler, false);
}

function noop() {}

function init(component, options) {
	component._handlers = blankObject();
	component._bind = options._bind;

	component.options = options;
	component.root = options.root || component;
	component.store = component.root.store || options.store;
}

function assign(tar, src) {
	for (var k in src) tar[k] = src[k];
	return tar;
}

function callAll(fns) {
	while (fns && fns.length) fns.shift()();
}

function destroy(detach) {
	this.destroy = noop;
	this.fire('destroy');
	this.set = this.get = noop;

	if (detach !== false) this._fragment.u();
	this._fragment.d();
	this._fragment = this._state = null;
}

function get(key) {
	return key ? this._state[key] : this._state;
}

function fire(eventName, data) {
	var handlers =
		eventName in this._handlers && this._handlers[eventName].slice();
	if (!handlers) return;

	for (var i = 0; i < handlers.length; i += 1) {
		var handler = handlers[i];

		if (!handler.__calling) {
			handler.__calling = true;
			handler.call(this, data);
			handler.__calling = false;
		}
	}
}

function observe(key, callback, options) {
	var fn = callback.bind(this);

	if (!options || options.init !== false) {
		fn(this.get()[key], undefined);
	}

	return this.on(options && options.defer ? 'update' : 'state', function(event) {
		if (event.changed[key]) fn(event.current[key], event.previous && event.previous[key]);
	});
}

function on(eventName, handler) {
	if (eventName === 'teardown') return this.on('destroy', handler);

	var handlers = this._handlers[eventName] || (this._handlers[eventName] = []);
	handlers.push(handler);

	return {
		cancel: function() {
			var index = handlers.indexOf(handler);
			if (~index) handlers.splice(index, 1);
		}
	};
}

function set(newState) {
	this._set(assign({}, newState));
	if (this.root._lock) return;
	this.root._lock = true;
	callAll(this.root._beforecreate);
	callAll(this.root._oncreate);
	callAll(this.root._aftercreate);
	this.root._lock = false;
}

function _set(newState) {
	var oldState = this._state,
		changed = {},
		dirty = false;

	for (var key in newState) {
		if (this._differs(newState[key], oldState[key])) changed[key] = dirty = true;
	}
	if (!dirty) return;

	this._state = assign(assign({}, oldState), newState);
	this._recompute(changed, this._state);
	if (this._bind) this._bind(changed, this._state);

	if (this._fragment) {
		this.fire("state", { changed: changed, current: this._state, previous: oldState });
		this._fragment.p(changed, this._state);
		this.fire("update", { changed: changed, current: this._state, previous: oldState });
	}
}

function _mount(target, anchor) {
	this._fragment[this._fragment.i ? 'i' : 'm'](target, anchor || null);
}

function _unmount() {
	if (this._fragment) this._fragment.u();
}

function _differs(a, b) {
	return a != a ? b == b : a !== b || ((a && typeof a === 'object') || typeof a === 'function');
}

function blankObject() {
	return Object.create(null);
}

module.exports = SvelteComponent;